<template>
  <div class="flex-column circleMain">
    <pageHead class="messageMainView_head" :hide-back="true" title="圈子" />
    <div class="main-content">
      <div class="searchBox_box">
        <searchBox
          :placeholder="placeholder"
          @click.native="toSearch"
          disabled
          class="mainView_search"
        />
      </div>
      <!--todo  我加入的圈子-->
      <myJoin ref="myJoin" @detail="hotClick"></myJoin>
      <!-- todo     热门圈子-->
      <div class="hot_box">
        <header class="hotCircle_title" v-if="ContSet.title_if">
          <span class="hotCircle_join">热门圈子</span>
          <span class="hotCircle_circle" @click.stop="goAllCircle"
          >全部圈子 ></span
          >
        </header>
        <hotCircle
          v-if="hotCircleList.length > 0"
          @joinCircle="handleJoinCircle"
          @quitCircle="handleJoinCircle"
          :ContSet="ContSet"
          :hot_list="hotCircleList"
          :noDataInfo="noDataInfo"
          @hotClick="hotClick"
        ></hotCircle>
        <van-skeleton
          v-if="!isLoaded && hotCircleList.length === 0"
          title
          :row="10"
        />
        <!-- todo 无数据展示 -->
        <div
          class="hotCircle_noData"
          v-if="isLoaded && hotCircleList.length === 0"
        >
          <div class="noData_box">
            <div class="noData_img"></div>
            <span>{{ noDataInfo.title1 || "暂未加入任何圈子" }}</span>
            <div class="join-button" @click="goAllCircle">
              {{ noDataInfo.title2 || "去加入 >" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageHead from "@/components/pageHead";
import searchBox from "@/components/searchBox";
import myJoin from "../components/myJoin";
import hotCircle from "@/components/hotCircle";
import {getListHotCircle} from "@/api/circle";

export default {
  components: {
    pageHead,
    searchBox,
    myJoin,
    hotCircle,
  },
  data() {
    return {
      placeholder: "搜索圈子",
      ContSet: {
        title_if: true,
      },
      hotCircleList: [],
      noDataInfo: {
        title1: "",
        title2: "",
      },
      isLoaded: false,
    };
  },
  created() {
    this.getHotList();
  },
  methods: {
    // 加入成功后刷新我的加入的列表
    handleJoinCircle() {
      this.$refs.myJoin.getMyJoinList();
    },
    // 跳转搜索页
    toSearch() {
      this.$router.push({
        name: "search",
        query: {
          activeIndex: "1",
          placeholder: '搜索圈子'
        },
      });
    },
    // 点击回调数据
    hotClick(item) {
      this.$router.push({
        path: "/app/circle/circleXq",
        query: {
          id: item.id,
          refreshCircle: true,
        },
      });
    },
    // 全部圈子页面
    goAllCircle() {
      this.$router.push({name: "circleMy"});
      // 设置选中下标
      localStorage.setItem("type", 1);
    },
    /**
     * todo 获取热门圈子列表
     */
    async getHotList() {
      try {
        const res = (await getListHotCircle()) || [];
        this.hotCircleList = res;
      } catch {
        this.hotCircleList = [];
      } finally {
        this.isLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.circleMain {
  height: 100%;
  overflow: hidden;

  .main-content {
    overflow-y: auto;
    flex: 1;
  }

  .searchBox_box {
    background: #fff;
    padding: 12.34px 0 17.27px;
  }

  .hot_box {
    padding: 18px 0 10px;
    background: #fff;

    .hotCircle_title {
      overflow: hidden;
      line-height: 20px;
      padding: 0 15px;

      .hotCircle_join {
        font-size: 15px;
        float: left;
        color: #000000;
      }

      .hotCircle_circle {
        font-size: 12px;
        color: #6d7278;
        float: right;
      }
    }

    .hotCircle_noData {
      display: flex;
      height: 380px;
      justify-content: center;
      align-items: center;
      text-align: center;

      .noData_box {
        .noData_img {
          width: 212px;
          height: 114px;
          background: url("~@/assets/imgs/circle/Slice39@2x.png") no-repeat;
          background-size: cover;
          margin: 0 auto;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .join-button {
        font-size: 14px;
        color: #fe5800;
        margin-top: 7px;
      }

      span {
        font-size: 12px;
        color: #6d7278;
      }
    }
  }

  ::v-deep .van-badge--fixed {
    top: 5px;
    right: 2px;
  }
}
</style>
