<template>
  <div class="myJoin_main">
    <div class="myJoin_title">
      <span class="my_join">我加入的</span>
      <span class="my_circle" @click.stop="myCircle">我的圈子 ></span>
    </div>

    <div v-if="quireList.length > 0" @touchstart.capture="touchStart" @touchend.capture="touchEnd">
      <van-grid class="circleList" :border="false" :column-num="5">
        <van-grid-item v-for="(item) in quireList" @click="handleDetail(item)" :key="item.id">
          <van-badge :content="getNumber(item)">
            <van-image
              round
              width="48px"
              height="48px"
              fit="cover"
              position="left"
              :src="item.avatar"
            />
          </van-badge>
          <div class="cir_title">
            <span v-if="item.title && item.title.length > 3">{{ item.title.substr(0, 3) }}...</span>
            <span v-else>{{ item.title }}</span>
          </div>
        </van-grid-item>
        <van-grid-item v-if="quireList.length > 4 && false">
          <div class="flex-row-h-center">
            <van-image
              round
              width="14px"
              height="14px"
              fit="cover"
              position="left"
              src="/images/circle/leftArrow.png"
            />
            <div class="flex-row-h-center cir_title">
              <span style="writing-mode: tb-rl;">左滑进入</span>
              <span style="writing-mode: tb-rl;">我的圈子</span>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>

    <div class="circle_noData" v-if="isLoaded  && quireList.length === 0">
      <span class="xl_icon"></span>
      <p class="xl_til">
        <span>暂未加入任何圈子</span>
        <span class="join-btn" @click="goAllCircle">去加入 ></span>
      </p>
    </div>

    <div style="margin-top: 20px;" v-if="!isLoaded && quireList.length === 0">
      <van-skeleton  title :row="2" />
    </div>
    <div v-if="userDetail.isCanCreateCircle" class="create-circle" @click="createNewCircle()">
      <img class="plus-sign" src="/images/circle/plusSign.png" alt="">
      <span class="text">创建圈子</span>
    </div>
  </div>
</template>

<script>
import {getListJoinedCircleMessage} from "@/api/circle";
import {mapGetters} from "vuex";

export default {
  name: 'myJoin', // 我加入的圈子
  data() {
    return {
      quireList: [],
      startX: 0,
      endX: 0,
      isLoaded: false,
    }
  },
  computed:{
    ...mapGetters(['userDetail']),
  },
  created() {
    this.getMyJoinList()
  },
  methods: {
    // 全部圈子页面
    goAllCircle() {
      this.$router.push({ name: "circleMy" });
      // 设置选中下标
      localStorage.setItem("type", 1);
    },
    touchStart(e) {
      // 记录初始位置
      this.startX = e.touches[0].clientX;
      console.log(this.startX)
    },
    touchEnd(e) {
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX;
      console.log(this.endX)
      // 左滑大于30距离删除出现
      if (this.startX - this.endX > 30) {
        console.log(11111111111111)
        this.myCircle()
      }
      this.startX = 0;
      this.endX = 0;
    },

    createNewCircle() {
      this.$router.push('/app/circle/myCreateCircle')
    },
    getNumber(item) {
      return item.unreadArticleCount > 0 ? item.unreadArticleCount : ''
    },
    // 跳转我加入的圈子页面
    myCircle() {
      this.$router.push({name: "circleMy"});
      // 我的
      localStorage.setItem('type', 0)
    },
    /**
     * todo 获取我加入的圈子列表
     */
    async getMyJoinList() {
      try {
        const res = await getListJoinedCircleMessage() || []
        if (res.length > 0) {
          this.quireList = res.slice(0, 5)
        }else {
          this.quireList = []
        }
      } finally {
        this.isLoaded = true
      }
    },
    handleDetail(item) {
      this.$emit('detail', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.myJoin_main {
  background: #fff;
  margin-bottom: 5px;

  .myJoin_title {
    overflow: hidden;
    padding: 0 15px;

    .my_join {
      font-size: 15px;
      float: left;
      color: #000000;
    }

    .my_circle {
      font-size: 12px;
      color: #6d7278;
      float: right;
      line-height: 20px;
    }
  }

  .circleList {
    //padding: 0 0 14px;
    display: flex;

    .cir_title {
      text-align: center;
      font-size: 12px;
      color: #333333;
      margin-top: 6px;
      letter-spacing: 0;
      white-space: nowrap;
    }

    img {
      border-radius: 50%;
    }

    .item_cir:last-child {
      margin-right: 28px;
    }

    .item_cir {
      margin-top: 20px;
      margin-right: 32px;

      ::v-deep .van-badge__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .cir_title {
        text-align: center;
        font-size: 12px;
        color: #333333;
        margin-top: 6px;
        letter-spacing: 0;
        white-space: nowrap;
      }
    }
  }

  .circle_noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 37px 0;

    .xl_icon {
      width: 20px;
      height: 20px;
      margin-bottom: 8px;
      display: inline-block;
      background: url('~@/assets/imgs/circle/Slice14@2x.png');
      background-size: cover;
    }

    .xl_til {
      display: block;
      font-size: 12px;
      color: #6d7278;
      line-height: 12px;
      .join-btn{
        margin-left: 4px;
        color: $mainColor;
      }
    }
  }

  .create-circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-top: 1px solid #f6f6f6;

    .plus-sign {
      width: 14px;
      height: 14px;
    }

    .text {
      margin-left: 4px;
      font-size: 14px;
      color: #fe5800;
      line-height: 14px;
    }

  }
}
</style>
